<template>
<div id="wrapper" class="container">


<header>
<div class="row">
    <div class="col-sm-6">
        <img width="260" src="./assets/logo.png">
    </div>
    <div class="col-sm-4">
    </div>
</div>

<!-- top navigation -->
<div id="topnav-wrap"><div id="topnav">
<div class="row">
<div class="col-sm-8">

    <ul class="nav nav-tabs">
        <li
            class="navigation"
            v-if="current_user"
            v-bind:class="{active: $route.name == 'Home' || $route.name == 'TimerRecord'}">
            <router-link :to="{ name: 'Recording', params: { }}">Zeiterfassung</router-link>
        </li>
        <li
            class="d-none d-sm-block"
            v-if="current_user && bx_access_invoices"
            v-bind:class="{active: $route.name=='TimesheetReport'}">
            <router-link :to="{ name: 'TimesheetReport', params: { }}">Report</router-link>
        </li>
        <li
            class="d-none d-sm-block"
            v-if="current_user && bx_access_invoices"
            v-bind:class="{active: $route.name=='InvoiceCreate'}">
            <router-link :to="{ name: 'InvoiceCreate', params: { }}">Verrechnung</router-link>
        </li>
    </ul> 
</div>
<ul class="nav nav-tabs col-sm-1 loginlogout">
        <li class=""
            v-if="current_user">
            <a class="logout" @click="logout" >Logout</a>
        </li>
        <li class=""
            v-if="!current_user"
            v-bind:class="{active: $route.name=='Login'}">
            <router-link :to="{ name: 'Login', params: {class: 'logout'}}" class="logout" >Login</router-link>
        </li>
    </ul>
    <Search v-if="current_user" />


</div>
</div><!-- /row -->
</div><!-- /topnav -->
</header>

<div id="content">
    <RunningTimer />
    <!-- router-view -->
    <router-view></router-view>
</div>

</div><!-- /wrapper -->
</template>



<script>
import Search       from './components/Search'
import RunningTimer       from './components/Timer/RunningTimer'
/* eslint-disable */

export default {
    name: 'app',
    computed: {
        current_user: function(){
            return this.$store.state.current_user;
        },
        bx_access_invoices: function(){
            return this.$store.state.bx_access_invoices;
        },
        modalComponent: function(){
          return this.$store.state.modalComponent;
        },
        modalComponentProps: function(){
          return this.$store.state.modalComponentProps;
        },
    },
    components: {
        Search,
        RunningTimer,
    },
    methods: {
        logout: function(){
            this.$store.state.BexioService.logout();
        },
    },
}
</script>

