<template>
<div v-if="record" class="text-left">
  <form class="form" @submit="saveRecord">
  <p>{{record.contact}}</p>
  <p>{{record.project}}</p>
  <div class="form-group">
    <textarea v-model="record.text"
      class="form-control"
      ></textarea>
  </div>
  <div class="form-group">
    <select v-model="record.client_service_id"
      required
      class="form-control">
          <option v-for="option in bx_client_services"
               v-bind:value="option.id"
               >{{option.name}}</option>
    </select>
  </div>
  <div class="checkbox">
      <label><input type="checkbox" v-model="record.allowable_bill">
      Eintrag ist abrechenbar </label>
  </div>
  <div v-if="type=='charge'" class="form-group">
    <label>Auslagen</label>
    <input v-model="record.charge"
      class="form-control"
      />
  </div>
  <div v-else class="row">
    <div class="form-group col col-xs-4">
      <label>Stunden</label>
      <input class="form-control"
        v-model="record.duration"
        required
        />
    </div>
    <div class="form-group col col-xs-4">
      <label>Stundensatz</label>
      <input class="form-control"
        v-model="record.rate"
        required
        />
    </div>
    <div class="form-group col col-xs-4">
      <label>Stundensatz</label>
      <input class="form-control"
        v-bind:value="work"
        disabled
        readonly
        />
    </div>
  </div>
  <div class="form-group">
    <button type="submit" class="btn btn-success">Speichern</button>
  </div>
    
  </form>
</div>
</template>
<script>
export default {
  props: ['record'],
  data: function(){
    return {
      type: 'duration',
    };
  },

  computed: {
    bx_client_services: function(){
      return this.$store.state.bx_client_services;
    },
    BexioService: function(){
      return this.$store.state.BexioService;
    },
    work: function(){
      return this.record.duration * this.record.rate;
    },
  },
  methods: {
    saveRecord: function(evt){
      let that = this;
      evt.preventDefault();
      if ('duration' == this.type && 0 == this.record.duration){
        alert('Keine Stunden eingetragen.');
        return;
      }
      if ('charge' == this.type && 0 == this.record.charge){
        alert('Keine Auslagen.');
        return;
      }
      if ('duration' == this.type && 0 == this.record.rate){
        if (!confirm('Ohne Umsatz speichern?')){
          return;
        }
      }
      let model= {
        allowable_bill: this.record.allowable_bill,
        client_service_id: parseInt(this.record.client_service_id),
        text: this.record.text.split('\n').join('<br/>'),
        tracking: {
            'date':  this.record.date,
        },
      };
      if ('duration' == this.type){
        let hours = parseInt(this.record.duration);
        let minutes = Math.round((this.record.duration - hours)*60);
        let duration = hours+':'+('0'+minutes).slice(-2);
        model.tracking.duration = duration;
        if (0 <= parseFloat(this.record.rate)) {
          model.charge = this.record.duration * parseFloat(this.record.rate);
        } else {
          model.charge= 0;
        }
      } else {
        model.tracking.duration = '00:00';
        model.charge   = parseFloat(this.charge);
      }
      this.$store.state.BexioService.sendRequest('POST', '/2.0/timesheet/'+this.record.id, model, function(data, err){
        if (null != err) {
          alert(err);
          return;
        } 
        that.$store.commit('reload_data');
        that.$emit('closeModal');
      });

    },
  },
  watch: {
    record: function(){
      this.type = this.record.duration ? 'duration' : 'charge';
    },
  }
}
</script>
