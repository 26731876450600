import Vue                  from 'vue'
import VueRouter            from 'vue-router'
import Vuex                 from 'vuex'
import App                  from './App'
import Recording            from './components/Timer/Recording'
import InvoiceCreate        from './components/Invoice/InvoiceCreate'
import TimesheetReport      from './components/Report/TimesheetReport'
import Login                from './components/Login'
import Dashboard            from './components/Dashboard'
import Test                 from './components/Test'
import BexioService         from './services/BexioService'
import DateService          from './services/DateService'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('./style.scss')
library.add(faSearch);
library.add(faStar);
window.Vue = Vue; // Otherways Datatable throws an error
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));

Vue.component('font-awesome-icon', FontAwesomeIcon);


const router = new VueRouter({
  routes: [
    { path: '/', component: Dashboard, name:'Dashboard'},
    { path: '/login', component: Login, name:'Login'},
    { path: '/recording', component: Recording, name:'Recording'},
    { path: '/invoice_create', name:'InvoiceCreate', component: InvoiceCreate, props: true },
    { path: '/report', name:'TimesheetReport', component: TimesheetReport, props: true },
  ]
});
// Firewall
router.afterEach((to, from) => {
    if (
        '/' != to.path
        && '/login' != to.path 
        && null == store.state.current_user
    ){
        if (router.currentRoute.name != 'Login'){
            router.push({name: 'Login'});
        }
    }
});

// Current user from LocalStorage
let current_user = localStorage.getItem('bexio_user');
if (null != current_user) {
    current_user = JSON.parse(current_user);
}

const store = new Vuex.Store({
    state: {
        router: router,
        contact_selected: null, // ? Deprecated?
        project_selected: null, // ? Deprecated?
        bx_client_services: [],
        bx_client_service_idx: {},
        bx_users: [],
        bx_user_idx: {},
        bx_taxes: [],
        bx_timesheet_status: [
            {id:1,name:'Offen'},
            {id:2,name:'In Arbeit'},
            {id:3,name:'Erledigt'},
            {id:4,name:'Fakturiert'},
            {id:5,name:'Geschlossen'},
            ],
        bx_default_contacts: [],
        bx_static_data_loaded: false,
        bx_access_invoices: true,
        DateService: DateService,
        BexioService: BexioService,
        current_user: current_user, // Logged-in user, null if not logged in
        search: '', 
        // Umweg, weil datatable kein Event handler bietet
        // Evt. umbauen. auf Datatable verzichten, kein Paging mehr.
        select_all: 1, 
        // Globaler reload Data Eintrag um Daten von Bexio neu zu laden
        reload_data: 1,
        current_timer_counter: 0,
    },
    mutations: {
        selectContact: function(state, contact){
            state.contact_selected = contact;
        },
        bx_client_services: function(state, data){
            state.bx_client_services = data;
        },
        bx_client_service_idx: function(state, data){
            state.bx_client_service_idx = data;
        },
        bx_users: function(state, data){
            state.bx_users = data;
        },
        bx_user_idx: function(state, data){
            state.bx_user_idx = data;
        },
        bx_taxes: function(state, data){
            state.bx_taxes = data;
        },
        bx_tax_idx: function(state, data){
            state.bx_tax_idx = data;
        },
        bx_default_contacts: function(state, data){
            state.bx_default_contacts = data;
        },
        current_user: function(state, obj) {
            localStorage.setItem('bexio_user', JSON.stringify(obj));
            state.current_user = obj;
        },
        bx_static_data_loaded: function(state, value) {
            state.bx_static_data_loaded = value;
        },
        bx_access_invoices: function(state, value) {
            state.bx_access_invoices = value;
        },
        current_timer_counter: function(state){
            state.current_timer_counter+=1;
        },
        reload_data: function(state){
            state.reload_data+=1;
        },
        search: function(state,value){
            state.search = value.trim();
        },
        select_all: function(state){
            state.select_all+=1;
        },
        modalComponent: function(state, modalComp){
            state.modalComponent = modalComp.name;
            state.modalComponentProps = modalComp.props;
            state.vue.$modal.show('app-modal');
        },

    },
    actions: {
        toggleBxDefaultContact: function(context, contact){
            let default_contacts = context.state.bx_default_contacts;
            let found = false;
            let new_idx = [];
            let new_default_contacts = [];
            for (let i=0; i<default_contacts.length;i++){
                if (default_contacts[i].id == contact.id){
                    found = true;
                } else {
                    new_default_contacts.push(default_contacts[i]);
                    new_idx.push(default_contacts[i].id);
                }
            }
            if (false == found){
                new_idx.push(contact.id);
                new_default_contacts.push(contact);
            }
            context.commit('bx_default_contacts', new_default_contacts);
            localStorage.setItem('bxt_default_contact_ids', new_idx.join(','));
        },
    }
})

// Register (this) in services as container
store.state.BexioService.container = store;

/* Filters */
Vue.filter('substr', function (value, start, length ) {
  return value.substr(start, length);
});
Vue.filter('number_format', function (value, decimal ) {
    if (isNaN(value)){
        return null;
    }
    return parseFloat(value).toFixed(decimal);
});
Vue.filter('date', function(value, format){
    if (null === value){
        return '';
    }
    if ((new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$")).test(value)) {
        var d = Date.fromFormat('Y-m-d', value);

    } else if ((new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$")).test(value)) {
        var d = Date.fromFormat('Y-m-d H:i:s', value);
    } else {
        console.log('could not create Date from Format');
        var d = new Date();
    }
    return d.format(format);
})

/* Vue instance */
const ve = new Vue({
    el: '#app',
    template: '<App/>',
    router,
    store,
    data: {
    },
    components: { 
        App,
    },
    created: function(){
        let that = this;
        // Verify login (created ist vor mounted, also jetzt)
        console.log('verify');
        that.$store.state.BexioService.verifyLogin(function(err) {
            if (null != err){
                that.$store.commit('current_user', null);
                if (that.$router.currentRoute.name != 'Login'){
                    that.$router.push({name: 'Login'});
                }
                return;
            }
            that.$store.state.BexioService.loadStaticValues(function(){
            });
        });
        window.setInterval(function(){
            console.log('updating');
            that.$store.commit('current_timer_counter');
        }, 10000);
        /*
         * Oauth2 Section
         */ 
        let search = location.search;
        if (0 == search.indexOf('?')){
            search = search.substr(1);
        }
        let query = search.split('&');
        let code = null;
        let state = null;
        for (var i=0; i< query.length; i++){
            if (0 == query[i].indexOf('code=')){
                code = query[i].replace('code=', '');
            }
            if (0 == query[i].indexOf('state=')){
                state = query[i].replace('state=', '');
            }
        }
        if (null != code && null != state){
            that.$store.state.BexioService.getOauth2Token({
                code: code,
                state: state
            }, function(user, err){
                let parsed = that.$store.state.BexioService.parseJwt(user.access_token)
                if (parsed.hasOwnProperty('company_user_id')){
                    user["user_id"] = parsed.company_user_id
                } else {
                    user["user_id"] = 1
                }
                let loc = Object.assign({}, window.location);
                loc = window.location.href.split('?')[0];
                loc+= window.location.hash;
                window.history.replaceState({}, document.title, loc);
                if (null != user && undefined == user.error){
                    that.$store.commit('current_user', user);
                    that.$router.push({name: 'Dashboard'});
                    that.$store.state.BexioService.loadStaticValues(function(){
                    });
                    return;
                } else if(undefined != user.error_description) {
                    alert(user.error_description);
                } else {
                    alert('Unbekannter Fehler beim Login');
                }
                that.$router.push({name: 'Login'});

            });
            return;
        }
    },
});
