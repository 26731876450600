export default  (function(){

    Date.prototype.format = function(format){
        var replace = {
            'd': ('0'+this.getDate()).slice(-2),
            'm': ('0'+(this.getMonth()+1)).slice(-2),
            'Y': this.getFullYear(),
            'H': ('0'+this.getHours()).slice(-2),
            'i': ('0'+this.getMinutes()).slice(-2),
            's': ('0'+this.getSeconds()).slice(-2),
        };
        for (var key in replace) {
            format = format.replace(key, replace[key]);
        }
        return format;
    }
    Date.fromFormat = function(format, str) {
        var date = new Date();
        date.setDate(1); // Muss gemacht werden, sonst gibt's Ende Monat Probleme
        var pos = 0;
        for (var i=0; i<format.length; i++){
            switch(format[i]){
                case 'Y':
                    date.setFullYear(str.substr(pos, 4));
                    pos+=4;
                    break;
                case 'm':
                    date.setMonth(parseInt(str.substr(pos, 2))-1);
                    pos+=2;
                    break;
                case 'd':
                    date.setDate(parseInt(str.substr(pos, 2)));
                    pos+=2;
                    break;
                case 'H':
                    date.setHours(parseInt(str.substr(pos, 2)));
                    pos+=2;
                    break;
                case 'i':
                    date.setMinutes(parseInt(str.substr(pos, 2)));
                    pos+=2;
                    break;
                case 's':
                    date.setSeconds(parseInt(str.substr(pos, 2)));
                    pos+=2;
                    break;
                default:
                    pos+=1;
            }
        }
        return date;
    }
    var S = {}

    
    return S;
})();

