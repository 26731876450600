var moment = require('moment');

export default {
    factory: function (){
        return {
            dt: moment().format("YYYY-MM-DD"),
            tm: moment().format('HH:mm'),
            dur: 0, // duration in minutes
            price_per_hour : 0,
            contact_id: 0,
            status_id:  3, // Erledigt
            allowable_bill: true,
            user_id:    0,
            client_service_id: 0,
            pr_project_id: null,
            text: ''
        }
    },
    calculateDurationSince: function(dt, tm, tm_end){
        let c = dt + ' '+tm+':00';
        let start = moment(c).format('x');
        let end = moment().format('x');
        if (tm_end){
            end = moment(dt + ' '+tm_end+':00').format('x');
        }
        return Math.round((end - start) / 1000 / 60);
    },
    calculateEndTime: function(dt, tm, dur){
        return moment(dt+' '+tm+':00')
            .add(dur, 'minutes')
            .format('kk:mm');
    },
    getHoursAndMinutes: function(dur){
        let hours = parseInt(dur/60);
        let minutes = dur - (hours * 60);
        return hours+':'+('0'+minutes).slice(-2);
    },
    getBexioTimerModel: function(timer){
        let charge = (timer.dur / 60) *  parseFloat(timer.price_per_hour);
        if (isNaN(charge)){
            charge = 0;
        }
        return {
            contact_id:     timer.contact_id,
            status_id:      timer.status_id, 
            allowable_bill: timer.allowable_bill,
            user_id:        timer.user_id,
            client_service_id: timer.client_service_id,
            pr_project_id:  timer.pr_project_id,
            text:           timer.text.split('\n').join('<br/>'),
            tracking: {
                'date':  timer.dt,
                'type': 'duration', // immer. ist nie timer
                'duration': this.getHoursAndMinutes(timer.dur),
            },
            charge: charge, 
        };
    },

}
