<template>
<div v-if="timer" class="alert alert-warning">
Aufzeichnung läuft: {{timer.text}}, seit {{running_since}} Minuten. 
    <button class="btn btn-sm btn-danger" @click="deleteTimer" title="Timer löschen">X</button>
    &nbsp;
    <button class="btn btn-sm btn-success" @click="stopTimer" title="Timer stoppen">Stopp</button>
</div>
</template>

<script>
import LocalStorageService from '@/services/LocalStorageService.js';
import TimerService from '@/services/TimerService.js';
export default {
    name: 'RunningTimer', 

    data: function(){
        return {
            timer: null,
            running_since: 0,
            timer_interval: null,
        };
    },
    computed: {
        current_timer_counter: function(){
            return this.$store.state.current_timer_counter;
        },
    },
    methods: {
        loadData: function(){
            this.timer = JSON.parse(LocalStorageService.getItem('current_timer', null));
            this.updateSince();
        },
        updateSince: function(){
            if (!this.timer){
                this.running_since = 0;
            } else {
                this.running_since = TimerService.calculateDurationSince(this.timer.dt, this.timer.tm);
            }
        },
        deleteTimer: function(){
            LocalStorageService.setItem('current_timer', JSON.stringify(null));
            this.loadData();
        },
        stopTimer: function(){
            let that = this;
            this.timer.dur = TimerService.calculateDurationSince(this.timer.dt, this.timer.tm);
            let model = TimerService.getBexioTimerModel(this.timer);
            this.$store.state.BexioService.sendRequest('POST', '/2.0/timesheet', model, function(data, err){
                if (err){
                    alert(err);
                } else {
                    LocalStorageService.setItem('current_timer', JSON.stringify(null));
                    that.loadData();
                    that.$store.commit('reload_data');
                }
            });
        }
    },
    created: function(){
        let that = this;
        that.loadData();
    },
    watch: {
        current_timer_counter: function(){
            return this.loadData();
        }
    },
}
</script>
