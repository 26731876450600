export default {
    container: null,
    sendRequest: function(method, path, data, callback) {
        if (null == this.container.state.current_user){
            return;
        }
        let user = this.container.state.current_user; 
        let base_url = 'https://timetracker.bexioapp.com/bexio_proxy.php';
        let bexio_service = this;
        let http = new XMLHttpRequest();
        let that = this;
        http.open(method, base_url+path, true);
        http.setRequestHeader('Authorization', 'Bearer '+user.access_token);
        http.setRequestHeader('Accept', 'application/json');
        if (null == data){
            http.send(null);
        } else {
            http.send(JSON.stringify(data));
        }
        http.onreadystatechange = function(){
            if (4 == http.readyState) {
                if (200 <= http.status && 400 > http.status) {
                    try {
                        let parsed = JSON.parse(http.responseText)
                        callback(parsed, null)
                    } catch (err){
                        callback(null, http.responseText)
                    }
                } else if (401 == http.status){
                    that.logout();
                } else {
                    try {
                        let parsed = JSON.parse(http.responseText)
                        if (parsed.hasOwnProperty('message')){
                            callback(null, parsed.message)
                        } else {
                            callback(null, http.status)
                        }
                    } catch (err){
                        callback(null, http.status)
                    }
                    
                }
            }
        }
    },
    duration2Hours: function(dur){
      if (null === dur){
        return null;
      }
      let a = dur.split(':');
      let hours = parseInt(a[0]);
      hours+= parseInt(a[1])/60;
      return hours;
    },
    getStatusCode: function(path, callback) {
        /* Returns the Http Status */ 
        if (null == this.container.state.current_user){
            return;
        }
        let user = this.container.state.current_user; 
        let base_url = 'https://timetracker.bexioapp.com/bexio_proxy.php';
        let bexio_service = this;
        let http = new XMLHttpRequest();
        http.open('GET', base_url+path, true);
        http.setRequestHeader('Authorization', 'Bearer '+user.access_token);
        http.setRequestHeader('Accept', 'application/json');
        http.send(null);
        http.onreadystatechange = function(){
            if (4 == http.readyState) {
                callback(http.status);
            }
        }
    },
    getAllInvoicableTimesheets(callback){
        let that = this;
        let method = 'POST';
        let endpoint = '/2.0/timesheet/search';
        let rows  = [];
        let filter = [];
        filter = [{field:'status_id', value: '3', criteria: '='}];
        let innerfunc = function(method, endpoint, data, offset, callback){
            let limit = 1000;
            that.sendRequest(
                method, 
                endpoint+'?order_by=id&limit='+limit+'&offset='+offset, 
                filter, function(dat){
                    let filtered_dat = dat.filter(function(row){
                        return row.allowable_bill;
                    });
                    rows.push(...filtered_dat)
                    if (dat.length < limit) {
                        callback(rows);
                    } else {
                        innerfunc(method, endpoint, data, offset+limit, callback);
                    }
                }
            );
        };
        innerfunc(method, endpoint, filter, 0, callback);    
    },

    /*
     * searches for records. 
     *
     * array params Fitlter for rows 
     *  int status_id (optional)
     *  int user_id (optional)
     *  int client_service_id (optional)
     *  int user_id (optional)
     *  int pr_project_id (optional)
     *  bool allowable_bill (optional)
     *  string range_start YYYY-MM-DD (optional), inclusive the limit
     *  string range_end YYYY-MM-DD (optional), inclusive the limit
     *
     */
    getTimesheets: function(params, callback){
      let that = this;
      let method = 'POST';
      let endpoint = '/2.0/timesheet/search';
      let rows  = [];
      let filter = [];
      if (undefined != params.status_id && 0 != params.status_id){
        filter = [{field:'status_id', value: String(params.status_id), criteria: '='}];
      }
      if (undefined != params.client_service_id && 0 != params.client_service_id) {
        filter = [{field:'client_service_id', value: String(params.client_service_id), criteria: '='}];
      }
      if (undefined != params.user_id && 0 != params.user_id) {
        filter = [{field:'user_id', value: String(params.user_id), criteria: '='}];
      }
      if (undefined != params.pr_project_id && 0 != params.pr_project_id) {
        filter = [{field:'pr_project_id', value: String(params.pr_project_id), criteria: '='}];
      }
      if (0 == filter.length){
        method  = 'GET';
        endpoint = '/2.0/timesheet';
        filter = null;
      }
      let innerfunc = function(method, endpoint, data, offset, callback){
        let limit = 1000;
        that.sendRequest(
          method, 
          endpoint+'?order_by=date_desc&limit='+limit+'&offset='+offset, 
          filter, function(dat){
            let filtered_dat = [];
            for (var i=0; i<dat.length; i++){
              if (undefined != params.range_start && dat[i].date < params.range_start) {
                continue;
              }
              if (undefined != params.range_end && dat[i].date > params.range_end) {
                continue;
              }
              if (undefined != params.allowable_bill && dat[i].allowable_bill != params.allowable_bill){
                continue;
              }
              filtered_dat.push(dat[i]);
            }
            rows.push(...filtered_dat)
            if (dat.length < limit || (undefined != params.range_start && dat[dat.length-1].date < params.range_start)) {
                callback(rows);
            } else {
                innerfunc(method, endpoint, data, offset+limit, callback);
            }
          }
        );
      };
      innerfunc(method, endpoint, filter, 0, callback);
    },

    getContacts(arrContactId, callback){
        var counter = arrContactId.length;
        let that = this;
        const rows = [];
        if (0 == counter){
            callback(rows);
        }
        for (let i=0; i<arrContactId.length; i++) {
            that.sendRequest('GET', '/2.0/contact/'+arrContactId[i], null, function(dat){
                if (null != dat && undefined != dat.id){
                    rows.push(dat);
                }
                counter--;
                if (0 >= counter) {
                    callback(rows);
                }
            });
        }
    },
    getContactIdx(arrContactId, callback) {
        this.getContacts(arrContactId, function(dat){
            let idx = {};
            for (let i=0; i<dat.length;i++){
                idx[dat[i].id] = dat[i];
            }
            callback(idx);
        });
    },
    getProjects(arrProjectId, callback){
        let counter = arrProjectId.length;
        let that = this;
        let rows = [];
        if (0 == counter){
            callback(rows);
        }
        for (let i=0; i<arrProjectId.length; i++) {
            that.sendRequest('GET', '/2.0/pr_project/'+arrProjectId[i], null, function(dat){
                rows.push(dat);
                counter--;
                if (0 >= counter) {
                    callback(rows);
                }
            });
        }
    },
    getProjectIdx(arrProjectId, callback) {
        this.getProjects(arrProjectId, function(dat){
            let idx = {};
            for (let i=0; i<dat.length;i++){
                idx[dat[i].id] = dat[i];
            }
            callback(idx);
        });
    },

    /*
     * returns tuple (invoice, err)
     *
     * contact_id: bx_contact_id
     * use_existing_draft: false=create new invoice any way
     * callback(invoice, err)
     */
    createInvoiceForContact: function(contact_id, use_existing_draft=true, callback){
        let that = this;
        that.sendRequest('POST', '/2.0/kb_invoice/search', [
                {field: 'contact_id', value: contact_id, criteria:'='},
                {field: 'kb_item_status_id', value: 7, criteria:'='}
            ], function(invoices){
                if (0 == invoices.length || !use_existing_draft){
                    that.sendRequest('POST',
                        '/2.0/kb_invoice', {
                            contact_id: contact_id,
                            user_id: that.container.state.current_user.user_id,
                            //mwst_type: 0, // Neu gemäss Vorgabe Bexio
                            //mwst_is_net: false, // Neu gemäss Vorgabe Bexio
                        },function(res){
                            if (null != res) {
                                callback(res, null)
                            } else {
                                callback(res, 'Fehler findInvoiceForContact');
                            }
                        });
                } else {
                    callback(invoices[0], null);
                }

            });
    },

    /* returns tuple (position, err)
     object invoice
     object params{text: string,show_pos_nr:boolean }
     function callback(position, err)
     */ 
    createSubposition: function(invoice, position, callback){
        if (null === position){
            // We need this for the case when we do not want to create but need the callback
            callback(null, 'Position missing');
            return;
        }
        let that = this;
        let data = Object.assign({
            text: '',
            show_pos_nr: true,
            // show_pos_prices: true, // Wird momentan nicht unterstuetzt
        }, position);
        that.sendRequest('POST', 
            '/2.0/kb_invoice/'+invoice.id+'/kb_position_subposition',
            data,
            function(res, err){
                callback(res, err);
            }
        );
    },
    /* returns tuple (position, err)
     object invoice
     object params{pagebreak: string, parent_id: integer}
     function callback(position, err)
     */ 
    createPagebreak: function(invoice, position, callback){
        if (null === position){
            // We need this for the case when we do not want to create but need the callback
            callback(null, 'position missing');
            return;
        }
        let that = this;
        let data = Object.assign({
            pagebreak: null,
            parent_id: null,
            // show_pos_prices: true, // Wird momentan nicht unterstuetzt
        }, position);
        that.sendRequest('POST', 
            '/2.0/kb_invoice/'+invoice.id+'/kb_position_pagebreak',
            data,
            function(res, err){
                callback(res, err);
            }
        );
    },

    /*
     * a key is: contactId_projectId_clientServiceId_userId
     * see TimesheetReport.vue
     *
     * 1. Search an open invoice for the customer. If not found, create
     * 2. Find Reports according to the filters and create invoicePositions
     *    for each of them
     *
     *  Callback: function(null or error)
     *
     *  DEPRECATED
     */
    createInvoice: function(key, callback) {
        let bexio_service = this;
        let a = key.split('_');
        if ('0' == a[0]) {
            callback('No contact');
        }
        bexio_service.sendRequest('POST', '/2.0/kb_invoice/search', [
                {field: 'contact_id', value: a[0], criteria:'='},
                {field: 'kb_item_status_id', value: 7, criteria:'='}
            ], function(invoices){
                if (0 == invoices.length) {
                    // Create an invoice, then call again
                    bexio_service.sendRequest('POST',
                        '/2.0/kb_invoice', {
                            contact_id: a[0],
                            user_id: bexio_service.container.state.current_user.user_id,
                        },function(res){
                            if (null != res) {
                                bexio_service.createInvoice(key, callback);
                            } else {
                                callback('Fehler');
                            }
                        });
                } else {
                    let invoice = invoices[0];
                    // Find the connected timesheets
                    let path = '/2.0/timesheet/search';
                    let data = [
                        {field: 'status_id', value: 3, criteria:'='},
                        {field: 'contact_id', value: a[0], criteria:'='},
                        {field: 'client_service_id', value: a[2], criteria:'='},
                        {field: 'user_id', value: a[3], criteria:'='},
                        ];
                    if (0 < parseInt(a[1])) {
                        data.push({field: 'pr_project_id', value: a[1], criteria:'='});
                    } else {
                       //  Filter nach pr_project_id is_null geht momentan nicht (26.1.). Darum
                       //  alle laden und weiter unten filtern.
                       // data.push({field: 'pr_project_id', value: '0', criteria:'is_null'});
                    }
                    bexio_service.sendRequest('POST', path, data, function(records) {
                            let record_counter = records.length;
                            if (0 == record_counter) {
                                console.log('nothing to invoice');
                                callback(null);
                            }
                            for (let j=0; j<records.length; j++){
                                // do not calculate if no charge
                                // And filter if project_id=0 but is assigned
                                // This is only necessary because search for is_null does not work
                                if (null == records[j].charge 
                                    || ('0' == a[1] && records[j].pr_project_id != null) 
                                ){
                                    record_counter--;
                                    if (0 >= record_counter) {
                                        callback(null);
                                    }
                                    return;
                                }
                                bexio_service.invoiceRecord(invoice, records[j], function(err){
                                    record_counter--;
                                    if (0 >= record_counter) {
                                        callback(err);
                                    }
                                });
                            }
                        });

                }
            });
    },
    invoiceRecord(invoice, record, parent_id, callback){
        let bexio_service = this;
        let duration = 0;
        let unit_price = 0;
        let amount     = 0;
        if (null != record.duration) {
            let a = record.duration.split(':');
            amount = (parseFloat(a[0]) + parseFloat(a[1]/60)).toFixed(6);
        } else {
            amount = 0;
        }
        if (0 < amount) {
            unit_price = (parseFloat(record.charge || 0) / amount).toFixed(2);
        } else {
            amount      = 1;
            unit_price  = parseFloat(record.charge).toFixed(2);
        }
        // Das folgende geht nicht ohne Preis rsp. Tax-ID
        let bx_user_idx = this.container.state.bx_user_idx;
        let bx_client_service_idx = this.container.state.bx_client_service_idx;
        let text =  '<b>'+record.text+'</b><br/>'+
            bx_user_idx[record.user_id].firstname+' '+bx_user_idx[record.user_id].lastname+
            ', '+bx_client_service_idx[record.client_service_id].name+
            ', '+(Date.fromFormat('Y-m-d', record.date)).format('d.m.Y');
        /*
        if (null !== record.duration){
            text+= ', '+ record.duration+'h';
        }
        */ 
        let data = {
            'text': text,
            'monitoring_id': record.id,
            'tax_id': localStorage.getItem('bxt_default_tax_id') || '0', 
            'unit_price': unit_price,
            'amount': amount,
            'account_id': this.container.state.bx_client_service_idx[record.client_service_id].account_id || null,
        };
        if (0 < parent_id){
            data['parent_id'] = parent_id;
        }
        bexio_service.sendRequest('POST', '/2.0/kb_invoice/'+invoice.id+'/kb_position_monitoring', data , function(res){
            if (null != res) {
                // Set the status of the timesheet to Fakturiert
                bexio_service.sendRequest('POST', '/2.0/timesheet/'+record.id, {
                    tracking: {
                        date: record.date,
                        duration: null == record.duration ? '00:00' : record.duration,
                    },
                    status_id: 4
                }, function(res){
                    callback(null);
                });
            } else {
                callback('Error create invoiceposition');
            }
        });

    },

    /*
     * invoices each record serial, one after the other.
     * This shall make sure that the order is same in bexio invoice
     */
    invoiceRecordsSerial(invoice, records, parent_id, callback){
        let that = this;
        let record = records.shift();
        that.invoiceRecord(invoice, record, parent_id, function(res){
            if (null != res){
                callback(res);
                return
            }
            if (0 == records.length){
                callback(null);
            } else {
                that.invoiceRecordsSerial(invoice, records, parent_id, callback);
            }
        });
    },


    getOauth2Token: function(params, callback){
        var url = 'https://timetracker.bexioapp.com/auth_token.php'
            +'?code='+params.code
            +'&state='+params.state
            +'&time='+(new Date()).getTime()
            +'&env='+process.env.NODE_ENV
            ;
        let http = new XMLHttpRequest();
        http.open('GET', url, true);
        http.send(null);
        http.onreadystatechange = function(){
            if (4 == http.readyState) {
                if (200 == http.status) {
                    callback(JSON.parse(http.responseText), null)
                } else {
                    callback(null, 'Fehler')
                }
            }
        }
    },
    verifyLogin: function(callback){
        if (null == this.container.state.current_user){
            callback('No user found');
            return;
        }
        this.sendRequest('GET', '/2.0/language', null, function(data, err){
            if (null != data){
                callback(null);
            } else {
                callback('Error');
            }
        });
    },
    loadStaticValues: function(callback) {
        let container = this.container;
        let bexio_service = this;
        let default_contact_id_list = localStorage.getItem('bxt_default_contact_ids') || '';

        bexio_service.sendRequest('GET', '/2.0/client_service', null, function(client_services, err){
        bexio_service.sendRequest('GET', '/3.0/users', null, function(users, err){
        bexio_service.sendRequest('GET', '/3.0/taxes',null, function(taxes, err){
        bexio_service.getContacts(default_contact_id_list.split(','), function(default_contacts){
            // Default Contacts
            container.commit('bx_default_contacts', default_contacts);

            // client services
            if (null != client_services){
                container.commit('bx_client_services', client_services.sort(function(a,b){
                    return a.name.localeCompare(b.name);
                }));
                let idx = {}
                for (let i=0; i<client_services.length;i++){
                    idx[client_services[i].id] = client_services[i];
                }
                container.commit('bx_client_service_idx', idx);
            }
            // Users
            if (null != users){
                container.commit('bx_users', users);
            }
            let idx = {}
            for (let i=0; i<users.length;i++){
                idx[users[i].id] = users[i];
            }
            container.commit('bx_user_idx', idx);
            
            // taxes
            if (null != taxes){
                let list = [];
                let idx  = {};
                for (let i=0; i<taxes.length; i++) {
                    if (taxes[i].is_active && 'sales_tax' == taxes[i].type) {
                        list.push(taxes[i]);
                        idx[taxes[i].id] = taxes[i];
                    }
                }
                container.commit('bx_taxes', list);
                container.commit('bx_tax_idx', idx);
                container.commit('bx_static_data_loaded', true);
            }

            // Check access to invoices
            bexio_service.getStatusCode('/2.0/kb_invoice?limit=1', function(stat){
                if (403 == stat){
                    //container.commit('bx_access_invoices', false);
                }
                callback();
            });
            
        });
        });
        });
        });
    },
    logout: function(){
        this.container.commit('current_user', null);
        this.container.commit('selectContact', null);
        this.container.commit('bx_client_services', null);
        this.container.commit('bx_client_service_idx', null);
        this.container.commit('bx_users', null);
        this.container.commit('bx_user_idx', null);
        this.container.commit('bx_taxes', null);
        if (this.container.state.router.currentRoute.name != 'Login'){
            this.container.state.router.push({name: 'Login'});
        }
    },
    parseJwt:function(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    
}
