<script>
/* eslint-disable */
export default {
    name: 'Search',
    data: function(){
        return {
            search: '',
        };
    },
    methods: {
        setSearch: function(evt){
            evt.preventDefault();
            this.$store.commit('search', this.search);
        }
    },
    created: function(){
        this.search = this.$store.state.search;
    },
}
</script>
<template>
    <form  role="search" @submit="setSearch" class="pull-right col-sm-3 quicksearch hidden-xs">
    <div class="input-group">
    <div class="input-group-btn">
            <button class="btn btn-default" type="submit"><font-awesome-icon icon="search" /></button>
        </div>
        <input type="text"
            v-model="search"
            @keyup="setSearch"
            class="form-control" 
            placeholder="Suche" 
            name="srch-term" >
        
    </div>
    </form>
</template>


