<template>
<div class="container" style="margin-top:50px; 
     width: 100%; max-width:100%;">
   

    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group checkbox">
                <label
                    title="Zusammenfassende Summenposition erstellen" 
                ><input type="checkbox" v-model="summary_row" 
                />Sammelposition</label>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group checkbox" >
                <label
                    title="Eine Seitenumbruch nach der zusammenfassenden Summenposition" 
                ><input type="checkbox" v-model="summary_row_pagebreak"
                  :disabled="!summary_row"
                />Seitenumbruch nach Summenzeile</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-group">
            <input type="text" v-model="summary_row_text" :disabled="!summary_row" class="form-control" placeholder="Text Summenzeile"/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-8 checkbox">

        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-4  offset-sm-8">
            <div class="form-group row">
                <div class="col-sm-12">
                    <select class="form-control" v-model="tax_id">
                    <option value="0">Mehrwertsteuer</option>
                        <option v-for="tax in bx_taxes" :key="tax.id"
                            v-bind:value="tax.id">{{tax.value}} {{ tax.display_name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-8 checkbox">
            <label><input type="checkbox" v-model="use_existing_draft"/>Wenn möglich bestehende Rechnungen (Entwurf) verwenden.</label>
        </div>
        <div class="col-12 col-sm-4">
            <div class="form-group text-right">
            <button 
                v-bind:disabled="!allow_invoicing"
                class="btn btn-default"
                @click="invoiceSelection"
                >Ausgewählte Verrechnen ({{selection_count}})</button>
            </div>
        </div>
    </div>
    
    <b-table v-if="static_data_loaded" striped hover responsive :items="reports" :fields="fields" :filter="search" @row-clicked="selectRow"></b-table>
    
    <div class="loader" v-if="!allow_invoicing"></div>

</div>
</template>



<script>
/* eslint-disable */
import Search from './../Search';
export default {
  name: 'InvoiceCreate',
  data: function(){
    return {
      tax_id: 0,
      status_id: 3,
      timesheets: [],
      selection: [],
      done: [],
      processing: '',
      all_selected: false,
      allow_invoicing: true,
      contact_idx: {},
      project_idx: {},
      reload_count: 0,
      use_existing_draft: true,
      summary_row: true,
      summary_row_pagebreak: true,
      summary_row_text: '',
      fields: [
        {key:'contact_name', label: 'Kunde', sortable: true},
        {key:'project_name', label: 'Projekt', sortable: true},
        {key:'duration', label: 'Stunden', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
        {key:'work', label: 'Honorar', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
        {key:'charge', label: 'Auslagen', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
        {key:'total', label: 'Total', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
      ],
    }
  },
  components: {
      Search,
  },
  computed: {
    BexioService: function(){
      return this.$store.state.BexioService;
    },
    bx_users: function(){
        return this.$store.state.bx_users;
    },
    bx_contacts: function(){
        return this.$store.state.bx_contacts;
    },
    bx_timesheet_status: function(){
        return this.$store.state.bx_timesheet_status;
    },
    bx_user: function(){
        return this.$store.state.bx_user;
    },
    user_idx: function(){
        return this.$store.state.bx_user_idx;
    },
    client_service_idx: function(){
        return this.$store.state.bx_client_service_idx;
    },
    bx_taxes: function(){
        return this.$store.state.bx_taxes;
    },
    bx_tax_idx: function(){
        return this.$store.state.bx_tax_idx;
    },
    search: function(){
        return this.$store.state.search;
    },
    static_data_loaded: function(){
      return this.$store.state.bx_static_data_loaded;
    },
    select_all: function(){
        return this.$store.state.select_all;
    },
    selection_count: function(){
      return this.selection.length;
    },
    reports: function(){
      let that = this;
      let records = {};
      that.timesheets.forEach(function(row){
        let key = '';
        key+= 0 < row.contact_id ? row.contact_id : '0';
        key+= '_';
        key+= 0 < row.pr_project_id ? row.pr_project_id : '0';
        if (undefined === records[key]){
          records[key] = {
            key: key,
            contact_id: row.contact_id,
            contact_name: 0<row.contact_id && that.contact_idx[row.contact_id] ? that.contact_idx[row.contact_id].name_1+' '+that.contact_idx[row.contact_id].name_2 : '',
            pr_project_id: row.pr_project_id,
            project_name: 0<row.pr_project_id && that.project_idx[row.pr_project_id] ? that.project_idx[row.pr_project_id].name : '',
            duration: 0.0,
            work: 0.0,
            charge: 0.0,
            total: 0.0,
            timesheet_ids: [],
            _rowVariant: -1 == that.selection.indexOf(key) ? 'default' : 'success',
          }
        }
        let duration = that.BexioService.duration2Hours(row.duration);
        records[key].timesheet_ids.push(row.id);
        records[key].duration+= 0 < duration ? parseFloat(duration) : 0.0;
        records[key].charge+= 0 < duration ? 0.0 : parseFloat(row.charge);
        records[key].work+= 0 < duration && 0 < row.charge ? parseFloat(row.charge) : 0.0;
        records[key].total+= 0 < row.charge ? parseFloat(row.charge) : 0.0;
      });
      return Object.values(records);
    },
  },
  created: function(){
      this.tax_id = localStorage.getItem('bxt_default_tax_id') || 0;
      this.summary_row_text = localStorage.getItem('summary_row_text') || '';
      this.summary_row = JSON.parse(localStorage.getItem('summary_row')) || false;
      this.summary_row_pagebreak = JSON.parse(localStorage.getItem('summary_row_pagebreak')) || false;
      this.use_existing_draft = JSON.parse(localStorage.getItem('use_existing_draft')) || false;
      this.loadData();
  },
  watch: {
     reload_count: function(){
         // triggered internally when all rows are invoiced
         this.allow_invoicing = (3 == this.status_id);
         this.all_selected = false;
         this.selection = [];
         this.loadData();
     },
     tax_id: function(){
         return localStorage.setItem('bxt_default_tax_id', this.tax_id);
     },
     use_existing_draft: function(){
         // save setting in localeStorage
         localStorage.setItem('use_existing_draft', this.use_existing_draft);
     },
     summary_row: function(){
         // save setting in localeStorage
         localStorage.setItem('summary_row', this.summary_row);
     },
     summary_row_pagebreak: function(){
         // save setting in localeStorage
         localStorage.setItem('summary_row_pagebreak', this.summary_row_pagebreak);
     },
     summary_row_text: function(){
         // save setting in localeStorage
         localStorage.setItem('summary_row_text', this.summary_row_text);
     },
     search: function(){
         this.selection = [];
     },
     select_all: function(){
         let that = this;
          if (0 < that.selection.length) {
              that.selection = [];
              that.all_selected = false;
          } else {
              let new_selection = [];

              let rows = document.querySelectorAll('[data-row_key]');
              rows.forEach(function(row){
                  new_selection.push(row.dataset.row_key);
              });
              this.selection = new_selection;
              that.all_selected = true;
          };
     },
  },
  methods: {
      loadData: function(){
          let that = this;
          this.$store.state.BexioService.getAllInvoicableTimesheets(function(rows){
              let projectIds = []; // to be loaded
              let contactIds = [];// to be loaded
              rows.forEach(function(row){
                if (0 < row.pr_project_id){
                    if (-1 == projectIds.indexOf(row.pr_project_id)){
                        projectIds.push(row.pr_project_id);
                    }
                }
                if (0 < row.contact_id){
                    if (-1 == contactIds.indexOf(row.contact_id)){
                        contactIds.push(row.contact_id);
                    }
                }
              });
              that.$store.state.BexioService.getContactIdx(contactIds, function(contact_idx){
              that.$store.state.BexioService.getProjectIdx(projectIds, function(project_idx){
                  that.contact_idx = contact_idx;
                  that.project_idx = project_idx;
                  that.timesheets = rows;
              });
              });
          });
      },
      selectRow: function(row){
          let index = this.selection.indexOf(row.key);
          if (-1 == index){
              this.selection.push(row.key);
          }else{
              this.selection.splice(index, 1);
          }
      },
      invoiceSelection(){
          let that = this;
          if (0 == that.selection){
              alert('Keine Zeilen ausgewählt');
              return;
          }
          if (that.summary_row && '' == that.summary_row_text.trim()){
              alert('Text für die Summenzeile eingeben');
              return;
          };
          if (0 == this.tax_id || undefined == this.bx_tax_idx[this.tax_id]) {
              alert('MwSt-Satz wählen');
              return;
          }
          this.allow_invoicing = false;
          if (0 == this.selection.length) {
              that.allow_invoicing = true;
              that.reload_count+= 1;
              return;
          }
          // Erstelle Contact-idx []report_id
          let idx = {};
          this.selection.forEach(function(key){
              let contact_id = key.split('_')[0];
              if (undefined === idx[contact_id]){
                  idx[contact_id] = [];
              }

              let report = that.reports.find(function(item){
                  return item.key == key;
              });
              idx[contact_id].push(...report.timesheet_ids);
          });
          let invoice_counter = Object.keys(idx).length;
          Object.keys(idx).forEach(function(contact_id){
              that.$store.state.BexioService.createInvoiceForContact(contact_id, that.use_existing_draft, function(invoice, err){
                  if (err) {
                      alert(err);
                      that.allow_invoicing = true;
                      that.reload_count+= 1;
                      return;
                  }
                  let data = that.summary_row ? {text:'<b>'+that.summary_row_text+'</b>'} : null;
                  that.$store.state.BexioService.createSubposition(invoice, data, function(summary_row, err){
                      let parent_id = summary_row ? summary_row.id : null;
                      let data =  that.summary_row && that.summary_row_pagebreak ? {pagebreak: null, parent_id: parent_id} : null;
                      that.$store.state.BexioService.createPagebreak(invoice, data, function(page_break, err){
                          let counter = idx[contact_id].length;
                          let records = [];
                          idx[contact_id].forEach(function(record_id){
                              that.$store.state.BexioService.sendRequest('GET', '/2.0/timesheet/'+record_id, null, function(record, err) {
                                  if (record){
                                      records.push(record);
                                  }
                                  counter--;
                                  if (0 >= counter){
                                      // Keine Ahnung warum, aber records laesst sich nicht sortieren...
                                      let sorted_records = Object.values(records);
                                      sorted_records.sort(function(q,v){
                                          return q['date'].localeCompare(v['date']);
                                      });
                                      that.$store.state.BexioService.invoiceRecordsSerial(invoice,sorted_records, parent_id, function(){
                                          invoice_counter--;
                                          if (0 >= invoice_counter){
                                              that.allow_invoicing = true;
                                              that.reload_count+= 1;
                                          }
                                      });
                                  } 
                              });
                              
                          });
                      });
                  });
              })
          });
      },
  }
}
</script>

