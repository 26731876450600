/*
 * This is as wrapper around the localStorage
 *
 *
 */
export default {
    /*
     * container must be set to vuex store object
     * usually in store.js
     *
     */
    container: null,

    hasLocalStorageSupport: (function(){
        try {
            return 'localStorage' in window && window['localStorage'] !== null;
        } catch (e) {
            return false;
        }
    })(),

    setItem: function(name, value){
        if (this.hasLocalStorageSupport){
            localStorage.setItem(name, value);
        } else {
            this.writeCookie(name, value, 1);
        }
    },

    getItem: function(name, default_value){
        // localStorage.getItem delivers null if not found
        // We must make sure that this behavior is repeated
        if (undefined == default_value){
            default_value = null;
        }

        if (this.hasLocalStorageSupport){
            let val = localStorage.getItem(name);
            return null == val ? default_value : val;
        } else {
            let val = this.readCookie(name);
            return null == val ? default_value : val;
        }
    },

    /**
     * @param {String} name The name of the property to read from this document's cookies
     * @return {?String} The specified cookie property's value (or null if it has not been set)
     */
    readCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    },

    /**
     * @param {String} name The name of the property to set by writing to a cookie
     * @param {String} value The value to use when setting the specified property
     * @param {int} [days] The number of days until the storage of this item expires
     */
    writeCookie: function(name, value, days) {
        var expiration = (function() {
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                return "; expires=" + date.toGMTString();
            }
            else {
                return "";
            }
        })();

        document.cookie = name + "=" + value + expiration + "; path=/";
    },
    
}

