<script>
/* eslint-disable */
export default {
    name: 'Test',
    methods: {
        testApi: function(){
            console.log('testing');

            const ApiService = this.$store.state.ApiService;
            ApiService.sendRequest('GET', '/2.0/bx_contact', null, function(){
            });
        }
    }
}
</script>
<template>
<div>
    <h1>Test</h1>
    <button @click="testApi">Test Api</button>
</div>
</template>


