<template>
<div class="hidden-xs">
  <div class="total-bar">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="total-calculator">
        Tagesrapport {{day|moment('DD.MM.YYYY')}}
        <button class="btn btn-default btn-small"
          @click="moveDay(-1)"
          >&lt;</button>
        <button class="btn btn-default btn-small"
          @click="moveDay(1)"
          >&gt;</button>
        </div>
      </div>
      <div class="col-6 col-md-2 text-center">
          <div class="total-calculator" style="padding: 18px 10px;">Stunden total: {{total_hours|number_format(2)}}</div>
      </div>
      <div class="col-6 col-md-2 text-center">
          <div class="total-calculator" style="padding: 18px 10px;">Honorar total: {{total_work|number_format(2)}}</div>
      </div>
      <div class="col-6 col-md-2 text-center">
          <div class="total-calculator" style="padding: 18px 10px;">Auslagen total: {{total_charge|number_format(2)}}</div>
      </div>
      <div class="col-6 col-md-2 text-center">
          <div class="total-calculator" style="padding: 18px 10px;">Umsatz: {{total|number_format(2)}}</div>
      </div>
    </div>
  </div>

<b-table striped hover responsive :items="records" :fields="fields" @row-clicked="editRecord"></b-table>

 <b-modal ref="recordEdit" hide-footer title="Eintrag bearbeiten">
  <div class="d-block text-center">
    <RecordEdit v-bind:record="record_to_edit" v-if="record_to_edit" @closeModal="closeModal"></RecordEdit>
  </div>
</b-modal>
</div>
</template>


<script>
/* eslint-disable */

import RecordEdit from './RecordEdit';

export default {
    props: ['day'],
    name: 'RecordList',
    data: function(){
        return {
            bx_timesheets: [], // The timesheets loaded from Bexio
            bx_project_idx: {}, // Projects for the timesheets
            contact_idx: {}, // Contacts for the timesheets
            fields: [
                {key:'contact', label: 'Kunde', sortable: true},
                {key:'project', label: 'Projekt', sortable: true},
                {key:'text', label: 'Tätigkeit', sortable: true},
                {key:'duration', label: 'Stunden', sortable: true},
                {key:'rate', label: 'Stundensatz', sortable: true},
                {key:'charge', label: 'Auslagen', sortable: true},
                {key:'total', label: 'Total', sortable: true},
            ],
            record_to_edit: null, // Id of the record to edit
        }

    },
    components:{
      RecordEdit,
    },
    computed: {
        BexioService: function(){
            return this.$store.state.BexioService;
        },
        current_user: function(){
            return this.$store.state.current_user;
        },
        reload_data: function(){
            return this.$store.state.reload_data;
        },
        records: function(){
            var a = [];
            var that = this;
            if (that.bx_timesheets && that.bx_project_idx && that.contact_idx){
                this.bx_timesheets.forEach(function(item){
                    var project = '';
                    if (that.bx_project_idx[item.pr_project_id]){
                        project = that.bx_project_idx[item.pr_project_id].name;
                    }
                    let contact = '';
                    if (that.contact_idx[item.contact_id]){
                        contact = that.contact_idx[item.contact_id].name_1+' '+that.contact_idx[item.contact_id].name_2;
                    }
                    let charge = item.charge;
                    let rate   = 0;
                    let total  = item.charge;
                    let duration = item.duration;
                    if (item.duration && 0 < item.duration){
                        charge = '';
                        rate = Math.round(total/item.duration);
                        duration = item.duration.toFixed(2);
                    } else {
                        duration = '';
                        rate = '';
                    }
                    var r = {
                        id: item.id,
                        date: item.date,
                        allowable_bill: item.allowable_bill,
                        client_service_id: item.client_service_id,
                        contact: contact,
                        project: project,
                        text: item.text.split('<br/>').join('\n'),
                        duration: duration,
                        charge: charge,
                        rate: rate,
                        total: total,
                        search: (contact+project+item.text).toLowerCase(),
                     };
                     a.push(r);
                });
            }
            if ('' != that.search.trim()){
                let aSearch = that.search.toLowerCase().split(' ');
                a = a.filter(function(item){
                    var res = true;
                    aSearch.forEach(function(s){
                        if ('' == s.trim()){
                            // pass
                        } else if (-1 == item.search.indexOf(s)){
                            res = false;
                        }
                    });
                    return res;
                });
            }
            return a;
        },
        search: function(){
            return this.$store.state.search;
        },
        total_hours: function(){
            var t = 0.0;
            this.bx_timesheets.forEach(function(item){
                if (item.duration){
                    t+= parseFloat(item.duration);
                }
            });
            return t;
        },
        total_charge: function(){
            var t = 0.0;
            this.bx_timesheets.forEach(function(item){
                if (!item.duration || 0 == item.duration){
                    t+= parseFloat(item.charge);
                }
            });
            return t;
        },
        total_work: function(){
            var t = 0.0;
            this.bx_timesheets.forEach(function(item){
                if (item.duration && 0 < item.duration){
                    t+= parseFloat(item.charge/item.duration);
                }
            });
            return t;
        },
        total: function(){
            var t = 0.0;
            this.bx_timesheets.forEach(function(item){
                t+= parseFloat(item.charge);
            });
            return t;
        },

    },
    created: function(){
        this.loadData();
    },
    watch: {
       reload_data: function(){
           this.loadData();
       },
       day: function(){
           this.loadData();
       },
    },
    methods: {
      closeModal: function(){
         this.$refs.recordEdit.hide();
      },
        loadData: function(){
            let that = this;
            that.bx_timesheets = [];
            that.bx_project_idx = null;
            that.contact_idx = null;
            var project_ids = [];
            var contact_ids = [];
            let search = [];
            search.push({ field: 'user_id', value: that.current_user.user_id, criteria: '=' });
            search.push({ field: 'status_id', value: 3, criteria: '=' });
            that.BexioService.sendRequest('POST', '/2.0/timesheet/search?order_by=date_desc', search, function(data, err){
                console.log(data, err)
                if (err){
                    console.log(err)
                    return
                }

                let filtered_list = [];
                var str_today = that.$moment(that.day).format('YYYY-MM-DD');
                data.forEach(function(item){
                    if (item.date != str_today){
                        return;
                    }
                    if (null != item.duration) {
                        let a = item.duration.split(':');
                        item.duration = parseFloat(a[0]) + parseFloat(a[1]/60);
                    }
                    filtered_list.push(item);
                    contact_ids.push(item.contact_id);
                    if (item.pr_project_id){
                        project_ids.push(item.pr_project_id);
                    }

                });
                that.bx_timesheets = filtered_list;
                that.BexioService.getProjectIdx(project_ids, function(idx){
                    that.bx_project_idx = idx;
                });
                that.BexioService.getContactIdx(contact_ids, function(idx){
                    that.contact_idx = idx;
                });
                
            });
        },
        editRecord(item, idx, evt){
          this.record_to_edit = Object.assign({}, item); // Clone Objekt
          this.$refs.recordEdit.show();
        },
        moveDay: function(interval){
            this.$emit('moveDay', interval);
        },
    },

}
</script>

