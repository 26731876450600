<template>
<div id="timesheet_report_wrap">
  <div id="timesheet_report">
  <div class="section">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class=" col-12 form-margin-bottom">
                <select v-model="owner_id" class="form-control">
                  <option value="0">Eigentümer</option>
                  <option value="0">Alle</option>
                  <option v-for="user in users"
                      v-bind:value="user.id"
                      >{{ user.firstname}} {{user.lastname}}</option>
                </select>
              </div>          
            </div><!-- end row -->

            <div class="row">
              <div class=" col-12 form-margin-bottom">
                  <select v-model="user_id" class="form-control">
                      <option value="0">Mitarbeiter</option>
                      <option value="0">Alle</option>
                      <option v-for="user in users"
                          v-bind:value="user.id"
                          >{{ user.firstname}} {{user.lastname}}</option>
                  </select>
              </div>        
            </div>
            <div class="row">
                <div class="col-12 form-margin-bottom">
                    <select v-model="range" class="form-control" required>
                    <option value="0">Bereich</option>
                        <option value="day">Tag</option>
                        <option value="week">Woche</option>
                        <option value="month">Monat</option>
                        <option value="month3">Quartal</option>
                        <option value="month6">Halbjahr</option>
                        <option value="month12">Jahr</option>
                        <option value="free">Bereich wählen</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class=" col-4">
                  <DatePicker 
                    format="dd.MM.yyyy"
                    :disabled="'free'!=range"
                    v-model="range_date_start"
                    input-class="form-control" 
                    :language="datepicker_lang"></DatePicker>
                </div>
                <div class="col-4 text-center">
                   <b-button size="md" variant="primary" @click="moveRange(-1)" v-if="'free' != range">&lt;</b-button>
                   <b-button size="md" variant="primary" @click="moveRange(1)" v-if="'free' != range">&gt;</b-button>
                </div>
                <div class=" col-4">
                  <DatePicker 
                    format="dd.MM.yyyy"
                    :disabled="'free'!=range"
                    input-class="form-control" 
                    v-model="range_date_end"
                    :language="datepicker_lang"></DatePicker>
                </div>
            </div>
          </div>


          <div class="col-6">
              <div class="col-12 total">
                  Stundentotal:
                  <span class="badge pull-right">{{ total_hours|number_format(2)}}</span>
              </div>
              <div class="col-12 total">
                  Honorartotal:
                  <span class="badge pull-right">{{ total_work|number_format(2)}}</span>
              </div>
              <div class="col-12 total">
                  Auslagentotal:
                  <span class="badge pull-right">{{ total_charge|number_format(2)}}</span>
              </div>
              <div class="col-12 total">
                  Gesamtumsatz:  <span class="badge pull-right">{{ total|number_format(2)}}</span>

              </div>
          </div>
        </div>

          <div class="row">
            <div class="col-12 checkbox">
                <div class="row">
                    <label class="col-4">
                        <input type="radio" value="0" v-model="allowable_bill" /> Nicht Anrechenbar
                    </label>
                    <label class="col-4">
                        <input type="radio" value="1" v-model="allowable_bill" /> Anrechenbar
                    </label>
                    <label class="col-4">
                        <input type="radio" value="-1" v-model="allowable_bill" /> Alle
                    </label>
                </div>
            </div>
          </div>
          
          <div class="row">
              <div class="col-12 checkbox">
                  <div class="row">
                      <label class="col-4">
                          <input type="radio" value="3" v-model="status_id" /> Erledigt
                      </label>
                      <label class="col-4">
                          <input type="radio" value="4" v-model="status_id" /> Fakturiert
                      </label>
                      <label class="col-4">
                          <input type="radio" value="-1" v-model="status_id" /> Alle
                      </label>
                  </div>
              </div>
          </div>
      </div>
    

    </div>
            
    <div class="row">
        <div class="col-6 col-sm-1">
            <!--<div class="border"><label></label></div>-->
        </div>
        <div class="form-group col-2">
        </div>
        <div class="col-6 col-sm-1">
            <!--<div class="border"><label></label></div>-->
        </div>
    </div>
<div class="row">
    <div class="col-6 col-sm-1">
        <!--<div class="border"><label></label></div>-->
    </div>
    <div class="col-6 col-sm-1">
        <!--<div class="border"><label></label></div>-->
    </div>
</div><!-- end row -->

<div class="row">
    <div class="col-6 col-sm-1">
        <!--<div class="border"><label></label></div>-->
    </div>
    <div class="col-6 col-sm-1">
        <!--<div class="border"><label></label></div>-->
    </div>
</div><!-- end row -->
    </div>

    </div>

<div class="total-bar">
        <div class="row">
        <div class="col-2 text-center total-select">
        </div>
        <div class="col-4">
        </div>
        
    </div></div>

<b-table v-if="static_data_loaded" striped hover responsive :items="reports" :fields="fields" :filter="search"></b-table>
<div class="loader" v-if="loading"></div>
</div>
</template>

<script>
/* eslint-disable */
import DatePicker from 'vuejs-datepicker';
import {en,de} from 'vuejs-datepicker/dist/locale';
export default {
    name: 'TimesheetReport',
    props: [],
    data: function(){
        return {
          allowable_bill: 1,
          status_id: 3,
          owner_id: 0, // alle Eigentuemer. Eigentuemer hat einen Kunden eroeffnet
          user_id: 0, // alle
          range: 'day', // day|week|month|month3|month6|month12
          range_date_start: new Date(),
          range_date_end: new Date(),
          contact_idx: {},
          project_idx: {},
          timesheets: [], 
          loading: false,
          fields: [
            {key:'contact_name', label: 'Kunde', sortable: true},
            {key:'project_name', label: 'Projekt', sortable: true},
            {key:'client_service_name', label: 'Tätigkeit', sortable: true},
            {key:'duration', label: 'Stunden', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
            {key:'work', label: 'Honorar', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
            {key:'charge', label: 'Auslagen', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
            {key:'total', label: 'Total', sortable: true, formatter: function(num){return 0 < num ? num.toFixed(2) : ''}, tdClass: 'text-right' },
          ],
        }
    },
    components: {
      DatePicker,
    },
    computed: {
        BexioService: function(){
          return this.$store.state.BexioService;
        },
        client_service_idx: function(){
            return this.$store.state.bx_client_service_idx;
        },
        datepicker_lang: function(){
          return de;
        },
        reports: function(){
          let that = this;
          let records = {};
          that.timesheets.forEach(function(row){
            if (0 < that.user_id && that.user_id != row.user_id) {
              console.log('user_id nomatch');
              return;
            }
            if (-1 < that.allowable_bill) {
              if (1 == that.allowable_bill && !row.allowable_bill) {
                return;
              }
              if (0 == that.allowable_bill && row.allowable_bill) {
                return;
              }
            }
            if (-1 < that.status_id && that.status_id != row.status_id) {
              return;
            }
            if (0 < row.contact_id 
                && 0 < that.owner_id 
                && that.contact_idx[row.contact_id].owner_id != that.owner_id
            ){
              return; 
            }
            let key = '';
            key+= 0 < row.contact_id ? row.contact_id : '0';
            key+= '_';
            key+= 0 < row.pr_project_id ? row.pr_project_id : '0';
            key+= '_';
            key+= 0 < row.client_service_id ? row.client_service_id : '0';
            if (undefined === records[key]){
              records[key] = {
                key: key,
                contact_id: row.contact_id,
                contact_name: 0<row.contact_id && that.contact_idx[row.contact_id] ? that.contact_idx[row.contact_id].name_1+' '+that.contact_idx[row.contact_id].name_2 : '',
                pr_project_id: row.pr_project_id,
                project_name: 0<row.pr_project_id && that.project_idx[row.pr_project_id] ? that.project_idx[row.pr_project_id].name : '',
                client_service_id: row.client_service_id,
                client_service_name: 0 < row.client_service_id &&  that.client_service_idx[row.client_service_id] ? that.client_service_idx[row.client_service_id].name : '',
                duration: 0.0,
                work: 0.0,
                charge: 0.0,
                total: 0.0,
              }
            }
            let duration = that.BexioService.duration2Hours(row.duration);
            records[key].duration+= 0 < duration ? parseFloat(duration) : 0.0;
            records[key].charge+= 0 < duration ? 0.0 : parseFloat(row.charge);
            records[key].work+= 0 < duration && 0 < row.charge ? parseFloat(row.charge) : 0.0;
            records[key].total+= 0 < row.charge ? parseFloat(row.charge) : 0.0;
          });
          return Object.values(records);
        },
        search: function(){
          return this.$store.state.search;
        },
        static_data_loaded: function(){
          return this.$store.state.bx_static_data_loaded;
        },
        taxes: function(){
          return this.$store.state.bx_taxes;
        },
        
        timesheet_status: function(){
          return this.$store.state.bx_timesheet_status;
        },
        total_hours: function(){
          let sum = 0.0;
          this.reports.forEach(function(report){
              sum+= parseFloat(report.duration)||0.0;
          });
          return sum.toFixed(2);
        },
        total_work: function(){
          let sum = 0.0;
          this.reports.forEach(function(report){
              sum+= parseFloat(report.work)||0.0;
          });
          return sum.toFixed(2);
        },
        total_charge: function(){
          let sum = 0.0;
          this.reports.forEach(function(report){
              sum+= parseFloat(report.charge)||0.0;
          });
          return sum.toFixed(2);
        },
        total: function(){
          return (parseFloat(this.total_work) + parseFloat(this.total_charge)).toFixed(2);
        },
        users: function(){
            return this.$store.state.bx_users;
        },
        user_idx: function(){
            return this.$store.state.bx_user_idx;
        },
    },
    created: function(){
        this.loadData();
    },
    watch: {
       range: function(){
         let c = new Date();
         if('month' == this.range){
           c.setDate(1);
         }
         else if ('month3' == this.range){
           // [0, 1, 2] [3, 4, 5] [6, 7, 8], [9,10,11] 
           c.setDate(1);
           if (3 > c.getMonth()){
             c.setMonth(0);
           } else if (6> c.getMonth()){
             c.setMonth(3);
           } else if (9 > c.getMonth()){
             c.setMonth(6);
           } else{
             c.setMonth(9);
           }
         }
         else if ('month6' == this.range){
           // [0, 1, 2, 3, 4, 5] [6, 7, 8, 9,10,11] 
           c.setDate(1);
           c.setMonth(6 > c.getMonth() ? 0 : 6);
         }
         else if ('month12' == this.range){
           c.setDate(1);
           c.setMonth(0);
         }
         this.range_date_start = c;
         this.calculateEnd();
         this.loadData();
       },
       range_date_start: function(){
           this.calculateEnd();
           this.loadData();
       },
    },
    methods: {
        calculateEnd: function(){
          if ('free' == this.range){
            return;
          }
          let end = new Date(this.range_date_start.getTime());
          let start = this.range_date_start;
          if ('week' == this.range) {
              end.setDate(end.getDate()+6);
          } else if ('month' == this.range) {
              end.setMonth(start.getMonth()+1);
              end.setDate(start.getDate()-1);
          } else if ('month3' == this.range) {
              end.setMonth(start.getMonth()+3);
              end.setDate(start.getDate()-1);
          } else if ('month6' == this.range) {
              end.setMonth(start.getMonth()+6);
              end.setDate(start.getDate()-1);
          }else if ('month12' == this.range) {
              end.setMonth(start.getMonth()+12);
              end.setDate(start.getDate()-1);
          } 
          this.range_date_end = end;
        },
        
        /* 
          We reload the data only if date range is changed
          All other filters are applied using the function records() 
        */
        loadData: function(){
          let that = this;
          let filter = {};
          that.loading = true,
          filter['range_start'] = this.range_date_start.format('Y-m-d');
          filter['range_end'] = this.range_date_end.format('Y-m-d');
          this.$store.state.BexioService.getTimesheets(filter, function(rows){
            let projectIds = [];
            let contactIds = [];
            for (let i=0;i<rows.length;i++) {
                if (0 < rows[i].pr_project_id){
                    if (-1 == projectIds.indexOf(rows[i].pr_project_id)){
                        projectIds.push(rows[i].pr_project_id);
                    }
                }
                if (0 < rows[i].contact_id){
                    if (-1 == contactIds.indexOf(rows[i].contact_id)){
                        contactIds.push(rows[i].contact_id);
                    }
                }
            }
            that.$store.state.BexioService.getContactIdx(contactIds, function(contact_idx){
            that.$store.state.BexioService.getProjectIdx(projectIds, function(project_idx){
                that.contact_idx = contact_idx;
                that.project_idx = project_idx;
                that.timesheets = rows;
                that.loading = false;
            });
            });
          });
        },
        /*
        * int dir -1/1
        */
        moveRange: function(dir){
          let d = new Date(this.range_date_start.getTime());
          if ('day' == this.range){
            d.setDate(d.getDate() + (dir * 1));
          }
          if ('week' == this.range){
            d.setDate(d.getDate() + (dir * 7));
          }
          if ('month' == this.range){
            d.setMonth(d.getMonth() + (dir * 1));
          }
          if ('month3' == this.range){
            d.setMonth(d.getMonth() + (dir * 3));
          }
          if ('month6' == this.range){
            d.setMonth(d.getMonth() + (dir * 6));
          }
          if ('month12' == this.range){
            d.setMonth(d.getMonth() + (dir * 12));
          }
          this.range_date_start = d;
        },

    }
}
</script>



