<script>
/* eslint-disable */
export default {
    name: 'Login',
    props: [],
    data: function(){
        return {
        };
    },
    computed: {
        oauth_server: function(){
            let redirect_uri = 'https://timetracker.bexioapp.com/';
            if (-1 == window.location.href.indexOf('timetracker.bexioapp.com')){
                redirect_uri = 'http://localhost:8080/';
            }
            return 'https://timetracker.bexioapp.com/auth.php?redirect_url='+encodeURIComponent(redirect_uri);
        },
    },
    components: {
    },
    methods: {
    },
    watch: {
    },
}
</script>
<template>
<div >
<div id="login">

    <p class="login-margin">
    <a v-bind:href="oauth_server"
        class="btn btn-default"
        >Einloggen bei Bexio</a>
    </p>
    <p><img width="600" src="./../assets/logo.png"></p>
    <p>Copyright Ctek AG</p>
</div>
</div>
</template>


