<script>
/* eslint-disable */
export default {
    name: 'Dashboard',
    props: [],
    data: function(){
        return {
        };
    },
    computed: {
    },
    components: {
    },
    methods: {
    },
    watch: {
    },
    created: function(){
        this.$router.push({name:'Recording'}) 
    },
}
</script>
<template>
<div >
<div id="dashboard">
<div class="alert alert-info">Bitte warten... </div>
</div>
</div>
</template>


