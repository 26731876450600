<template>
<div >
<div class="row" style="margin-top: 50px">
    <!-- Kontaktauswahl -->
    <div class="col col-12 col-sm-3">
        <ul class="list-group" >
            <li v-for="contact in defaultContacts"
                class="list-group-item">
                <span class="pull-right">
                  <font-awesome-icon icon="star" @click="toggleDefaultContact(contact)" />
                </span>
                <a class="contacts" @click="selectContact(contact)">
                {{ contact.name_1}} {{contact.name_2}}
                </a>
            </li>
        </ul>
        <div class="form-group">
            <input v-model="contact_search"
                placeholder="Kunde suchen"
                class="form-control">
        </div>
        <ul class="list-group" v-if="contacts" >
            <li v-for="contact in contacts"
                class="list-group-item"
                >
                <span class="pull-right">
                <font-awesome-icon icon="star" @click="toggleDefaultContact(contact)" 
                  class="contact_selector"
                  v-bind:class="{
                        'active': (-1 != defaultContactIds.indexOf(contact.id)),
                        }"  />
                </span>
                <a class="contacts" @click="selectContact(contact)">
                {{ contact.name_1}} {{contact.name_2}}
                </a>

            </li>
        </ul>
    </div><!-- /col -->

    <!-- Erstellen eines neuen Records -->
    <div class="col col-12 col-sm-9">
        <RecordCreate :contact="contact" v-bind:day="day"></RecordCreate>
    </div>

</div><!-- /row -->

<RecordList v-bind:day="day" @moveDay="moveDay"/>

<div class="loader" v-if="loading"></div>

</div>
</template>

<script>
import RecordCreate from './RecordCreate';
import RecordList   from './RecordList';

/* eslint-disable */
export default {
    name: 'Recording',
    data: function(){
        return {
            project_list: [],
            contact_search: '',
            contact: null, // aktuell gewählter Kontakt
            contacts: [], // Ergebnis der Suche
            day: new Date(), 
            loading: false,
        };
    },
    components: {
        RecordCreate,
        RecordList,
    },
    computed: {
        current_contact_id: function(){
            return this.contact==null ? 0 : contact.id;
        },
        defaultContacts: function(){
            return this.$store.state.bx_default_contacts;
        },
        defaultContactIds: function(){
            let a =  this.$store.state.bx_default_contacts;
            let idx = [];
            a.forEach(function(item){
                idx.push(item.id);
            });
            return idx;
        },
        bx_client_services: function(){
            return this.$store.state.bx_client_services;
        },
        bx_users: function(){
            return this.$store.state.bx_users;
        },
        bx_timesheet_status: function(){
            return this.$store.state.bx_timesheet_status;
        },
    },
    methods: {
        editRecord: function(local_id) {
            this.edit_record_id = local_id;
        },
        toggleDefaultContact: function(contact){
            this.$store.dispatch('toggleBxDefaultContact', contact);
        },
        selectContact: function(contact){
            this.contact = contact;
        },
        moveDay: function(interval){
            let new_date = new Date();
            new_date.setTime(this.day.getTime());
            new_date.setDate(this.day.getDate() + interval);
            this.day = new_date;
        },
    },
    watch: {
        contact_search: function(){
            let that = this;
            this.$store.state.BexioService.sendRequest('POST', '/2.0/contact/search', [
            {
                field: 'name_1',
                value: this.contact_search,
                criteria: 'like',
            },
            ], function(data){
                that.contacts = data;
            });
        },
    },
}
</script>



