<template>
<form v-on:submit="submitForm" method="POST">
<div class="row">
  <div class="col col-12 col-sm-4">
    <div v-if="!contact" class="alert alert-info">
    Kunde wählen oder suchen
    </div>
    <div v-else class="alert alert-success">
        Kunde: <strong>{{contact.name_1}} {{contact.name_2}}</strong>
    </div>
    <div class="form-group">
      <select v-model="pr_project_id" class="form-control" value="0">
      <option value="0">Projekt</option>
          <option v-for="option in bx_projects" :key="option.id"
                  v-bind:value="option.id"
          >{{option.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <select v-model="client_service_id"
          v-on:change="rememberClientServiceId"
          required
          class="form-control">
              <option v-for="option in bx_client_services" :key="option.id"
                   v-bind:value="option.id"
                   >{{option.name}}</option>
      </select>
    </div>
  </div>

  <div class="col col-12 col-sm-8">
    <div class="form-group">
      <label><input type="radio" v-model="record_type" value="duration"> Zeit</label>
      <label><input type="radio" v-model="record_type" value="charge"> Auslagen</label>
    </div>
    <div class="form-group">
      <div>*h = Stunden, *hr = Stundensatz</div>
      <textarea v-model="text"
          v-on:keyup="shortcuts"
          required
          class="text-area" placeholder="Text"></textarea>
    </div>
    <div class="row" v-if="record_type=='charge'">
      <div class="col col-xs-4">
        <div class="form-group">
            <label>Auslagen</label>
            <input type="text"
                required
                class="form-control"
                v-model="charge"
                />
        </div>
      </div>
    </div>

    <div class="row" v-if="record_type=='duration'">
      <div class="col col-xs-4">
        <div class="form-group" >
          <label>Stunden</label>
          <input type="text"
                 required
                 pattern="^[0-9\.]+$"
                 v-model="duration" placeholder="Dauer (h)"
                 class="form-control" />
        </div>
      </div>
      <div class="col col-xs-4">
        <div class="form-group">
          <label>Stundensatz</label>
          <input type="text"
                 required
                 pattern="^[0-9\.]+$"
                 title="Dezimalzahl, z.B. 100.30"
                 v-model="price_per_hour"
                 v-on:change="rememberPricePerHour" 
                 placeholder="Stundensatz (CHF)"
                 class="form-control" />
        </div>
      </div>

      <div class="col col-xs-4">
        <div class="form-group">
          <label>Umsatz</label>
          <input type="text"
              disabled
              readonly
              class="form-control"
              v-bind:value="price_per_hour*duration|number_format(2)"
              />
        </div>
      </div>
    </div><!-- /row -->

    <div class="row">
        <div class="col col-12 col-xs-12 col-sm-12 col-md-6  text-left">
            <div class="checkbox">
                <label><input type="checkbox" v-model="allowable_bill">
                Eintrag ist abrechenbar </label>
            </div>
        </div>
        <div class="col col-12  col-xs-12 col-sm-12 col-md-6 text-right">
            <div class="form-group" style="height:50px;">
                <div class="row">
                    <div class="col col-6">
                        <button
                            type="button"
                            @click="startTimer"
                            class="btn btn-default btn-block"
                            v-bind:disabled="!showButton || !contact"
                            >Start &gt;</button>
                    </div>
                    <div class="col col-6">
                        <button
                            type="submit"
                            class="btn btn-default btn-block"
                            v-bind:disabled="!showButton || !contact"
                            >Erstellen</button>
                    </div>
                </div>
            </div>
        </div>
    </div><!-- /row -->
    <div v-if="error" class="alert alert-danger">{{error}}</div>
    <div v-if="success" class="alert alert-success">{{success}}</div>
  </div><!-- /col-8, rechte Spalte -->

</div>
</form>
</template>


<script>
import LocalStorageService from '@/services/LocalStorageService.js';
import TimerService from '@/services/TimerService.js';
var moment = require('moment');

/* eslint-disable */
export default {
    name: 'RecordCreate',
    props: ['contact', 'day' ],
    data: function(){
        return {
            success:  null,
            error:    null,
            showButton: true,
            bx_projects: [],
            record_type: 'duration', //duration|charge
            price_per_hour: localStorage.getItem('bxt_price_per_hour') || '',
            duration: 0,
            charge: 0,
            pr_project_id:  '0',
            client_service_id: localStorage.getItem('bxt_client_service_id') || '',
            text:           '',
            allowable_bill: true,
        }
    },
    computed: {
        bx_client_services: function(){
            return this.$store.state.bx_client_services;
        },
        BexioService: function(){
            return this.$store.state.BexioService;
        },
    },
    watch: {
       contact: function(){
           let that = this;
           that.pr_project_id = 0;
           that.showButton = false;
           let project_search = [{ field: 'contact_id', value: this.contact.id, criteria: '=' }];
           that.BexioService.sendRequest('POST', '/2.0/pr_project/search', project_search, function(data){
                that.bx_projects = data.filter(function(item){
                    return item.pr_state_id == 1 || item.pr_state_id == 2
                });
                that.showButton = true;
           });
       },
    },
    methods: {
        startTimer: function(){
            let that = this;
            let timer = TimerService.factory();
            if ('' == this.text){
                alert('Text eingeben');
                return;
            }
            timer.contact_id = this.contact.id;
            timer.allowable_bill = this.allowable_bill;
            timer.user_id = this.$store.state.current_user.user_id;
            timer.client_service_id= parseInt(this.client_service_id);
            timer.pr_project_id = 0 == this.pr_project_id ? null : parseInt(this.pr_project_id);
            timer.text = this.text;
            timer.price_per_hour = parseFloat(this.price_per_hour)
            let cur_t = JSON.parse(LocalStorageService.getItem('current_timer', null));
            if (cur_t && cur_t.dt) {
                cur_t.dur = TimerService.calculateDurationSince(cur_t.dt, cur_t.tm);
                let model = TimerService.getBexioTimerModel(cur_t);
                that.$store.state.BexioService.sendRequest('POST', '/2.0/timesheet', model, function(data, err){
                    if (err) {
                        alert(err);
                    } else {
                        // Tell ContactTimer to reload the list
                        that.$store.commit('reload_data');
                        that.$store.commit('current_timer_counter');
                    }
                });
            }
            LocalStorageService.setItem('current_timer', JSON.stringify(timer)); 
            this.$store.commit('current_timer_counter');
        },
        submitForm: function(evt){
            evt.preventDefault();
            let that = this;
            this.showButton=false;
            this.success = null;
            this.error   = null;
            let model= {
                contact_id: this.contact.id,
                status_id:  3, // Erledigt
                allowable_bill: this.allowable_bill,
                user_id:    this.$store.state.current_user.user_id,
                client_service_id: parseInt(this.client_service_id),
                pr_project_id: 0 == this.pr_project_id ? null : parseInt(this.pr_project_id) ,
                text:       this.text.split('\n').join('<br/>'),
                tracking: {
                    'date':  this.$moment(this.day).format('YYYY-MM-DD'),
                    'type': 'duration', // immer. ist nie timer
                },
            }
            if ('duration' == this.record_type){
                let hours = parseInt(this.duration);
                let minutes = Math.round((this.duration - hours)*60);
                let duration = hours+':'+('0'+minutes).slice(-2);
                model.tracking.duration = duration;
                if (0 <= parseFloat(this.price_per_hour)) {
                    model.charge = this.duration * parseFloat(this.price_per_hour);
                } else {
                    model.charge= 0;
                }

                if (0 == model.charge && !confirm('Ohne Umsatz speichern?')){
                    that.showButton = true;
                    return false;
                }

            } else {
                model.tracking.duration = '00:00';
                model.charge   = parseFloat(this.charge);
            }
            this.$store.state.BexioService.sendRequest('POST', '/2.0/timesheet', model, function(data, err){
                if (null != err) {
                    that.error = err;
                } else {
                    that.success = 'Gespeichert';
                }
                that.showButton = true;
                // Tell ContactTimer to reload the list
                that.$store.commit('reload_data');
            });

        },
        rememberClientServiceId: function(evt) {
            localStorage.setItem('bxt_client_service_id', this.client_service_id);
            let client_service = this.$store.state.bx_client_service_idx[this.client_service_id];
            if (null != client_service.default_price_per_hour){
                this.price_per_hour = client_service.default_price_per_hour;
                this.rememberPricePerHour();
            }
        },
        rememberPricePerHour: function(evt) {
            localStorage.setItem('bxt_price_per_hour', this.price_per_hour);
        },
        shortcuts: function(evt) {
            let re = RegExp("[0-9\.]+h[ \n]");
            let matches = this.text.match(re);
            if (null != matches){
                this.record_type='duration';
                this.duration = parseFloat(matches[0]);
                this.text = this.text.replace(matches[0], '');
            }

            let re2 = RegExp("([0-9])+hr[ \n]");
            let matches2 = this.text.match(re2);
            if (null != matches2){
                this.record_type='duration';
                this.price_per_hour = parseFloat(matches2[0]);
                this.text = this.text.replace(matches2[0], '');
            }
        },
    }
}
</script>

